/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Container from "./styles"

const Image = ({ fluid, ...props }) => {
  return (
    <Container>
      <Img fadeIn={false} fluid={fluid} {...props} />
    </Container>
  )
}

Image.propTypes = {
  fluid: PropTypes.object.isRequired,
}

export default Image
