export const toggleScrollBar = show => {
  if (show) {
    document.documentElement.classList.remove("hideScrollBar")
  } else {
    document.documentElement.classList.add("hideScrollBar")
  }
}

export const toggleNoScroll = noscroll => {
  if (noscroll) {
    document.body.classList.add("noscroll")
  } else {
    document.body.classList.remove("noscroll")
  }
}
