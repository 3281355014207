import styled, { keyframes, css, breakpoints } from "@xstyled/styled-components"

import theme from "@utils/theme"

const { headerHeight, headerMobileHeight } = theme

const titleIn = keyframes`
  0% {
    transform: translateY(${headerHeight}vw);
  }
  25% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-${headerHeight}vw);
  }
`

const titleInMobile = keyframes`
  0% {
    transform: translateY(${headerMobileHeight}vw);
  }
  25% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-${headerMobileHeight}vw);
  }
`

export const Name = styled.header(
  breakpoints({
    xs: css`
      display: ${p => (p.hasVisited ? "none" : "block")};
      position: fixed;
      top: calc(20px - 1.5vw);
      left: 0;
      width: 100%;
      color: light;
      text-align: center;
      overflow: hidden;
      height: ${headerMobileHeight}vw;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      }
    `,
    md: css`
      top: 0;
      height: ${headerHeight}vw;
    `,
  })
)

export const NameInner = styled.div(
  breakpoints({
    xs: css`
      animation: ${titleInMobile} 3s 1 both;
    `,
    md: css`
      animation: ${titleIn} 3s 1 both;
    `,
  })
)
