import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import useSessionStorage from "react-use/lib/useSessionStorage"
import PropTypes from "prop-types"

import Layout from "@components/layout"

import SEO from "@atoms/Seo"

import Projects from "@organisms/Home/Projects"
import IntroName from "@organisms/Home/IntroName"
import BottomCopy from "@organisms/Home/BottomCopy"

const IndexPage = ({ data, location }) => {
  const doc = data.prismic.projects.edges.slice(0, 1).pop()
  if (!doc) return null
  const projects = data.prismic.projects.edges
  const [hasVisited] = useSessionStorage("hasVisited")
  const [stateHasVisited, setStateHasVisited] = useState(false)

  useEffect(() => {
    setStateHasVisited(hasVisited)
    window.scrollTo(0, 1)
  }, [])

  return (
    <Layout location={location}>
      <SEO title="home" />

      {!stateHasVisited && (
        <IntroName title="Chris Biron" secondaryTitle="Visual Designer" />
      )}

      <Projects projects={projects} />

      <BottomCopy>
        Chris is an independent Visual Designer based in London. He combines raw
        aesthetics with refined interactions to create memorable digital
        experiences
      </BottomCopy>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      projects: allProjects {
        edges {
          node {
            project_title
            project_year
            home_image
            home_imageSharp {
              childImageSharp {
                fluid(
                  maxWidth: 2000
                  quality: 80
                  srcSetBreakpoints: [400, 600, 800, 1000, 1300, 1600]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default IndexPage
