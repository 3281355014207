import styled from "@xstyled/styled-components"

import theme from "@utils/theme"

const { headerHeight, divider } = theme

const Item = styled.div`
  padding: ${headerHeight / divider}vw 0;
`

export default Item
