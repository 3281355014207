import React, { useState, useRef } from "react"
import useRafLoop from "react-use/lib/useRafLoop"
import PropTypes from "prop-types"

import { Desktop, Container, Word, SVG } from "./styles"

const WORDS = [
  "Websites",
  "Apps",
  "Identities",
  "Ecommerce",
  "Interfaces",
  "Installations",
  "Software",
]
const TIME_LIMIT = 1000

const Star = ({ rotation }) => {
  return (
    <SVG r={rotation} viewBox="0 0 18 20">
      <g
        transform="translate(-541.000000, -624.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path d="M550,643.5 C551.05,643.5 551.7,642.75 551.7,641.5 C551.7,639.45 550.45,638.35 550.35,634.35 C553.8,636.45 554.1,638.05 555.85,639.1 C556.9,639.75 557.95,639.55 558.45,638.6 C559,637.7 558.6,636.75 557.55,636.15 C555.8,635.15 554.15,635.75 550.55,633.8 C554.15,631.85 555.75,632.35 557.55,631.35 C558.6,630.75 559,629.8 558.45,628.85 C557.95,627.95 556.9,627.75 555.85,628.35 C554.05,629.45 553.8,631.15 550.35,633.3 C550.45,629.15 551.7,628.05 551.7,626 C551.7,624.75 551.05,624 550,624 C548.95,624 548.3,624.75 548.3,626 C548.3,628.1 549.65,629.2 549.75,633.35 C546.2,631.2 545.95,629.45 544.15,628.35 C543.1,627.75 542.05,627.95 541.55,628.85 C541,629.8 541.35,630.75 542.45,631.35 C544.25,632.35 545.85,631.85 549.45,633.8 C545.85,635.75 544.2,635.15 542.45,636.15 C541.35,636.75 541,637.7 541.55,638.6 C542.05,639.55 543.1,639.75 544.15,639.1 C545.95,638.05 546.25,636.4 549.75,634.3 C549.65,638.35 548.3,639.45 548.3,641.5 C548.3,642.75 548.95,643.5 550,643.5 Z" />
      </g>
    </SVG>
  )
}

Star.propTypes = {
  rotation: PropTypes.string,
}

const KeyWords = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(0)
  const r = useRef(0)

  const totalWords = WORDS.length
  const rotationIncr = 180 / totalWords
  let t0 = typeof performance !== "undefined" ? performance.now() : 0

  const incrementIndex = () => {
    const nextIndex = currentIndex === totalWords - 1 ? 0 : currentIndex + 1
    r.current += rotationIncr

    setLastIndex(currentIndex)
    setCurrentIndex(nextIndex)
  }

  useRafLoop(() => {
    const t1 = performance.now()

    if (t1 - t0 >= TIME_LIMIT) {
      incrementIndex()

      t0 = t1
    }
  })

  return (
    <Desktop>
      <Star rotation={r.current.toFixed(2)} />
      <Container>
        {WORDS.map((word, i) => {
          const isActive = i === currentIndex
          const isOut = i === lastIndex
          const isHidden = !isActive && !isOut

          return (
            <Word
              key={word}
              isActive={isActive}
              isOut={isOut}
              isHidden={isHidden}
            >
              {word}
            </Word>
          )
        })}
      </Container>
    </Desktop>
  )
}

export default KeyWords
