import React, { useRef, useState, useLayoutEffect, useEffect } from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"
import useRafLoop from "react-use/lib/useRafLoop"
import useWindowSize from "react-use/lib/useWindowSize"
import useSessionStorage from "react-use/lib/useSessionStorage"

import Image from "@atoms/Image"

import HeaderItem from "@molecules/HeaderItem"

import slugify from "@utils/slugify"
import getScrollPos from "@utils/getScrollPos"
import getFirstProject from "@utils/getFirstProject"
import { toggleNoScroll, toggleScrollBar } from "@utils/scroll"

import { Header, HeaderInner, HeaderIntroAnim, Container, Item } from "./styles"

const TIME_LIMIT = 2000
const UNLOCK_SCROLL = 1000

const HomeProjects = ({ projects }) => {
  const { width, height } = useWindowSize()
  const [hasVisited, setHasVisited] = useSessionStorage("hasVisited", false)
  const [triggerProjects, setTriggerProjects] = useState(hasVisited)

  const prevScroll = useRef(0)
  const headerInnerRef = useRef(null)
  const headerIntroAnimRef = useRef(null)
  const containerRef = useRef(null)

  const firstProject = getFirstProject(projects)
  const isMobile = width <= 769
  const t0 = typeof performance !== "undefined" ? performance.now() : 0

  const loopScroll = () => {
    const diff = getScrollPos() - prevScroll.current

    if (getScrollPos() + height >= document.body.scrollHeight && diff > 0) {
      window.scrollTo(0, 0)
    }

    if (getScrollPos() === 0 && diff < 0) {
      window.scrollTo(0, document.body.scrollHeight - height)
    }

    prevScroll.current = getScrollPos()
  }

  const loopTitles = () => {
    const h = document.body.scrollHeight
    const y = getScrollPos()

    headerInnerRef.current.style.transform = `translateY(-${(y / h) * 100}%)`
  }

  useRafLoop(() => {
    if (!headerInnerRef || !headerInnerRef.current) return
    const t1 = performance.now()

    if (t1 - t0 >= TIME_LIMIT && !triggerProjects && !hasVisited) {
      setTriggerProjects(true)
    }

    if (triggerProjects) {
      if (!isMobile) {
        loopScroll()
      }
      loopTitles()
    }
  })

  useEffect(() => {
    if (triggerProjects && !hasVisited) {
      setTimeout(() => {
        setHasVisited(true)
        toggleNoScroll(false)
      }, UNLOCK_SCROLL)
    }

    const yOffset = triggerProjects ? 0 : 100

    containerRef.current.style.transform = `translateY(${yOffset}vh)`
    containerRef.current.style.transition = hasVisited
      ? "none"
      : "transform 2s cubic-bezier(0.19, 1, 0.22, 1)"

    headerIntroAnimRef.current.style.transform = `translateY(${
      triggerProjects ? 0 : 100
    }%)`
    headerIntroAnimRef.current.style.transition = hasVisited
      ? "none"
      : "transform 1s"
  }, [triggerProjects])

  useLayoutEffect(() => {
    toggleScrollBar(false)

    if (hasVisited) {
      setTriggerProjects(true)
      toggleNoScroll(false)
    } else {
      toggleNoScroll(true)
    }

    return () => {
      toggleScrollBar(true)
      toggleNoScroll(false)
    }
  }, [])

  return (
    <>
      <Header>
        <HeaderIntroAnim ref={headerIntroAnimRef}>
          <HeaderInner ref={headerInnerRef}>
            {projects.map(el => {
              const p = el.node
              const title = RichText.asText(p.project_title)
              const year = p.project_year ? RichText.asText(p.project_year) : ""

              return <HeaderItem key={title} title={title} year={year} />
            })}
            {!isMobile && firstProject.project && (
              <HeaderItem title={firstProject.title} year={firstProject.year} />
            )}
          </HeaderInner>
        </HeaderIntroAnim>
      </Header>

      <Container ref={containerRef}>
        {projects.map((el, i) => {
          const isCritical = i === 0 || false
          const pp = el.node
          const title = RichText.asText(pp.project_title)
          const slug = slugify(title)

          return (
            <Item to={`/${slug}`} key={slug}>
              {pp.home_image && (
                <Image
                  className="lazyload"
                  fluid={
                    pp.home_imageSharp &&
                    pp.home_imageSharp.childImageSharp &&
                    pp.home_imageSharp.childImageSharp.fluid
                  }
                  loading={isCritical ? "eager" : "lazy"}
                  alt={title}
                />
              )}
            </Item>
          )
        })}
        {!isMobile && firstProject.project && (
          <Item to={`/${firstProject.slug}`}>
            {firstProject.project.home_image && (
              <Image
                fluid={
                  firstProject.project.home_imageSharp &&
                  firstProject.project.home_imageSharp.childImageSharp &&
                  firstProject.project.home_imageSharp.childImageSharp.fluid
                }
                loading="lazy"
                alt={firstProject.title}
              />
            )}
          </Item>
        )}
      </Container>
    </>
  )
}

HomeProjects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.object,
    })
  ),
}

export default HomeProjects
