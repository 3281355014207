import styled, { breakpoints, css } from "@xstyled/styled-components"
import { Link } from "gatsby"

import theme from "@utils/theme"

const { headerHeight, headerMobileHeight } = theme

export const Header = styled.header(
  breakpoints({
    xs: css`
      position: fixed;
      top: calc(20px - 1.5002308402585411vw);
      left: 0;
      width: 100%;
      color: light;
      text-align: center;
      overflow: hidden;
      height: ${headerMobileHeight}vw;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      }
    `,
    md: css`
      top: 0;
      height: ${headerHeight}vw;
    `,
  })
)

export const Container = styled.div`
  position: relative;
  z-index: 10;

  transform: translateY(100vh);
  will-change: transform;
`

export const Item = styled(Link)(
  breakpoints({
    xs: css`
      display: block;
      height: 100vh;
      min-height: -webkit-fill-available;
      padding: 5 0;

      img {
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
      }
    `,
    md: css`
      padding: 5;
    `,
  })
)

export const HeaderIntroAnim = styled.div`
  transform: translateY(100%);
`

export const HeaderInner = styled.div``
