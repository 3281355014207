import React from "react"

import PropTypes from "prop-types"

import Heading from "@atoms/Heading"

import Item from "./styles"

const HeaderItem = ({ year, title }) => {
  return (
    <Item>
      <Heading noPad secondary={year} styleVariant="large">
        {title}
      </Heading>
    </Item>
  )
}

HeaderItem.propTypes = {
  title: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
}

export default HeaderItem
