import React from "react"
import PropTypes from "prop-types"

import KeyWords from "@molecules/KeyWords"

import Copy from "./styles"

const BottomCopy = ({ children, stateHasVisited }) => {
  return (
    <Copy
      px={{ xs: 2, md: 4 }}
      pt={{ xs: "15px", md: 5 }}
      pb={{ xs: "35px", md: 5 }}
      hasVisited={stateHasVisited}
    >
      {children}
      <KeyWords />
    </Copy>
  )
}

BottomCopy.propTypes = {
  children: PropTypes.node.isRequired,
  stateHasVisited: PropTypes.bool,
}

export default BottomCopy
