import React from "react"
import PropTypes from "prop-types"

import HeaderItem from "@molecules/HeaderItem"
import { Name, NameInner } from "./styles"

const IntroName = ({ title, secondaryTitle }) => {
  return (
    <Name>
      <NameInner>
        <HeaderItem title={title} year={secondaryTitle} />
      </NameInner>
    </Name>
  )
}

IntroName.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
}

export default IntroName
