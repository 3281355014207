import { RichText } from "prismic-reactjs"
import slugify from "@utils/slugify"

const getFirstProject = projects => {
  const project = projects[0].node
  const title = RichText.asText(project.project_title)

  return {
    project,
    title,
    slug: slugify(title),
    year: project.project_year ? RichText.asText(project.project_year) : "",
  }
}

export default getFirstProject
