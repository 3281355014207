import styled, { css, keyframes, breakpoints } from "@xstyled/styled-components"

const isOutAnim = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(-100%) rotate(-7deg);
  }
`

const isActiveAnim = keyframes`
  0% {
    transform: translateY(100%) rotate(7deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
`

export const Desktop = styled.span(
  breakpoints({
    xs: css`
      display: none;
    `,
    md: css`
      display: none;
    `,
    lg: css`
      display: inline;
    `,
  })
)

export const Container = styled.span`
  position: relative;
  font-family: 1;
  text-transform: uppercase;
  font-size: 0.8vw;
  top: -1.1vw;
  overflow: hidden;
  height: 1.51vw;
  display: inline-block;
  margin-left: 5px;
  width: 8vw;
`

export const Word = styled.span`
  position: absolute;
  top: -0.1vw;
  left: 0;
  padding: 5px 0;

  opacity: ${p => (p.isHidden ? 0 : 1)};
  ${p =>
    p.isOut &&
    css`
      animation: ${isOutAnim} 0.666s 1 both;
    `}
  ${p =>
    p.isActive &&
    css`
      animation: ${isActiveAnim} 0.666s 1 both;
    `}
`

export const SVG = styled.svg`
  position: relative;
  width: 1.2vw;
  height: 1.2vw;
  top: -1.3vw;
  transition: transform 0.666s ease-in-out;
  transform: rotate(-${p => p.r}deg);
`
