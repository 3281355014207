import styled, { keyframes, css, breakpoints } from "@xstyled/styled-components"

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Copy = styled.h2Box(
  breakpoints({
    xs: css`
      color: light;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 5.4vw;
      line-height: 1.3;

      ${p =>
        !p.hasVisited &&
        css`
          animation: ${fadeIn} 1s 0.5s 1 both;
        `}
    `,
    md: css`
      font-size: 3vw;
      line-height: 1.1;
    `,
  })
)

export default Copy
