import styled from "@xstyled/styled-components"

const Container = styled.span`
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: contain !important;
    }
  }
`

export default Container
